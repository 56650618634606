import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import Script from "next/script";

import { useRouter } from "next/router";

import { useConfigContext } from "~/providers/ConfigContextProvider";

const COOKIE_NAME = "ikon-cookie-shown";

import styled from "styled-components";
import Link from "next/link";
import Button from "../styled/Button";

const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s;
  @media (any-pointer: fine) {
    &:hover {
      background-color: #555;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        padding: 0.05em ${props.theme.spacePx(
          breakpoint,
          9
        )} 0  ${props.theme.spacePx(breakpoint, 9)};
      `;
    })}
`;

const Aside = styled.aside<{ opacity: number }>`
  position: fixed;
  background: #fff;
  transition: opacity 0.5s;
  z-index: 3000;
  opacity: ${({ opacity }) => opacity};
  background-color: var(--ikon-hl-color, #ff0);
  display: flex;
  align-items: center;
  flex-direction: row;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      ${props.theme.textStyle(breakpoint, `h4Heading`)};
        max-width: calc(100vw - ${(
          props.theme.space(breakpoint, 8) * 2
        ).toFixed(2)}px);
        min-height: ${props.theme.spacePx(breakpoint, 5)};            
        left: ${props.theme.spacePx(breakpoint, 8)};            
        bottom: ${props.theme.spacePx(breakpoint, 8)};            
        padding: ${props.theme.spacePx(breakpoint, 8)};
        column-gap: ${props.theme.spacePx(breakpoint, 8)};
      `;
    })}
`;

export const UserTracking = () => {
  const config = useConfigContext();
  const [showPopup, setShowPopup] = useState(false);
  const [fadingOut, setFadeingOut] = useState(false);
  const router = useRouter();

  const trackView = useCallback(
    (url: any) => {
      if ((window as any)?.ga || (window as any)?.gtag) {
        if ((window as any)?.ga) {
          (window as any)?.ga("send", "pageview", {
            page: url,
            title: document.title,
          });
        }

        if ((window as any)?.gtag) {
          (window as any)?.gtag("event", "page_view", {
            page_title: document.title,
            page_location: url,
            send_to: config.ga4TagProperty,
          });
        }
      }

      try {
        const trackUser = Cookies.get(COOKIE_NAME);

        if (trackUser !== "yes" && trackUser !== "no") {
          setShowPopup(true);
        }
      } catch (e) {
        setShowPopup(true);
      }
    },
    [config.ga4TagProperty]
  );

  const fadeOut = () => {
    setFadeingOut(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 750);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const tEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowPopup(false);
      }
    };

    if (typeof document !== "undefined") {
      document.body.addEventListener("keyup", tEscape);
    }

    router.events.on("routeChangeComplete", trackView);
    return () => {
      if (typeof document !== "undefined") {
        document.body.removeEventListener("keyup", tEscape);
      }
      router.events.off("routeChangeComplete", trackView);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const trackUser = Cookies.get(COOKIE_NAME);

      if (trackUser !== "yes" && trackUser !== "no") {
        setShowPopup(true);
      }
    } catch (e) {
      setShowPopup(true);
    }
  }, [setShowPopup]);

  return (
    <>
      {config.gaProperty && (
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${config.gaProperty}', 'auto');
          ga('send', 'pageview');
        `}
        </Script>
      )}

      {config.gTagProperty && (
        <Script id="google-gtag" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.gTagProperty}');
        `}
        </Script>
      )}

      {config.ga4TagProperty && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.ga4TagProperty}`}
          />
          <Script id="google-ga4-tag" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.ga4TagProperty}', {});
          `}
          </Script>
        </>
      )}

      {config.gaProperty && showPopup && (
        <Aside
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="cpopup_label"
          aria-describedby="cpopup_desc"
          opacity={fadingOut ? 0 : 1}
        >
          <div>
            This site uses cookies.
            <br />
            Find out more in our{" "}
            <Link href="/legal-policy" passHref>
              <a>privacy policy</a>
            </Link>
            .
          </div>
          <CloseButton
            onClick={() => {
              try {
                Cookies.set(COOKIE_NAME, "yes", { expires: 365 });
              } catch (e) {}

              fadeOut();
            }}
          >
            Close
          </CloseButton>
        </Aside>
      )}
    </>
  );
};
