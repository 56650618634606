import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Link from "next/link";

import { VisuallyHidden } from "../styled/VisuallyHidden";

import { LoadingIcon } from "../ui/LoadingIcon";
import Button from "../styled/Button";
import InputText from "../styled/InputText";
import { FormFields } from "../forms/FormFields";

import { useConfigContext } from "~/providers/ConfigContextProvider";

const NewsletterInput = styled(InputText)<{ isError: boolean }>`
  color: ${({ isError }) =>
    isError ? "var(--ikon-error-color, #c00)" : "#000"};
  min-width: 50vw;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        text-transform: none;
        padding-top: ${props.theme.fontValignPx(breakpoint, "h3Heading")};
        padding-left: ${props.theme.spacePx(breakpoint, 9)};
        padding-right: ${props.theme.spacePx(breakpoint, 9)};
        `;
    })}

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
          min-width: 25vw;
        `;
      }
    )}
`;

const NewsletterButton = styled(Button)<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isLoading }) =>
    isLoading
      ? "var(--ikon-hl-color, #ff0) !important"
      : "var(--ikon-hl-color, #ff0)"};
  color: ${({ isLoading }) =>
    isLoading ? "var(--ikon-hl-color, #ff0) !important" : "#000"};
  transition: background-color 0.3s, color 0.3s;

  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "all")};

  @media (any-pointer: fine) {
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        padding:0 ${props.theme.spacePx(
          breakpoint,
          9
        )} 0.1em  ${props.theme.spacePx(breakpoint, 9)};
      `;
    })}
`;

const Form = styled.div``;


const Message = styled.div<{ isError?: boolean }>`
  color: ${({ isError }) =>
    isError ? "var(--ikon-error-color, #c00)" : "#000"};
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin-bottom:${props.theme.spacePx(breakpoint, 7)}; 
        .form-field.consent {
          margin-top:${props.theme.spacePx(breakpoint, 8)};          
        }
        `;
    })}
`;

const StyledFormFields = styled(FormFields)`
  .text {
    display: flex;
  }

  .error-message {
    display: block;
    width: 100%;
  }
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        
        .form-field.consent {
          margin-top:${props.theme.spacePx(breakpoint, 8)};
          .checkboxes {
            .gfield_consent_label:first-of-type {
              width: calc(100% - 5px - ${props.theme.spacePx(breakpoint, 8)});
            }
          }
        }
        `;
    })}
`;

export const NewsletterForm = () => {

  const hiddenFieldRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const config = useConfigContext();
  
  const [formState, setFormState] = useState<any>({
    errors: {
      email: true,
      consent: true,
    },
    touched: {
      email: false,
      consent: false,
    },
    submitting: false,
    triedToSubmit: false,
    submitted: false,
    submitError: false,
    slowDown: false,
  });

  useEffect(() => {
    let isMounted = true;
    if (!hiddenFieldRef?.current) return;

    hiddenFieldRef.current.value = "bad-name";
    let t: ReturnType<typeof setTimeout> | null = setTimeout(() => {
      t = null;
      if (isMounted && hiddenFieldRef.current) {
        hiddenFieldRef.current.value = "good-name";
      }
    }, 6666);

    return () => {
      isMounted = false;
      if (t) clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      {formState.submitted && (
        <Message>
          <b>THANK YOU!</b>
          <br />
          Please check your inbox for a confirmation email and
          verify your email address to subscribe.
        </Message>
      )}

      {formState.submitError && (
        <Message isError>
          Unfortunately we could not process your subscription
          request. Please ensure your email address is entered with
          all lower case letters and try again.
          <br />
          Many thanks.
        </Message>
      )}

      {formState.slowDown && (
        <Message isError>
          <b>Ooops!</b>
          <br />
          You&#39;re very quick. Are you a bot? No? Please wait a
          few seconds and then try it again.
        </Message>
      )}

      {!formState.submitted && !formState.submitError && (
        <form
          noValidate
          autoComplete="new-email"
          action="#"
          onSubmit={async (event) => {
            event.preventDefault();

            if (hiddenFieldRef.current.value === "bad-name") {
              setFormState({
                ...formState,
                slowDown: true,
              });
              return;
            }

            if (
              !formState.touched.consent ||
              !formState?.touched?.consent
            ) {
              setFormState({
                ...formState,
                touched: {
                  email: true,
                  consent: true,
                },
                triedToSubmit: true,
                errors: {
                  email: !formState?.touched?.email
                    ? true
                    : formState?.errors?.email,
                  consent: !formState?.touched?.consent
                    ? true
                    : formState?.errors?.consent,
                },
                slowDown: false,
              });
            } else {
              if (
                formState?.errors?.email ||
                formState?.errors?.consent
              ) {
                setFormState({
                  ...formState,
                  touched: {
                    email: true,
                    consent: true,
                  },
                  triedToSubmit: true,
                  slowDown: false,
                });
              } else {
                setFormState({
                  ...formState,
                  submitError: false,
                  submitted: false,
                  submitting: true,
                  triedToSubmit: true,
                  slowDown: false,
                });

                const body: any = {};

                Object.keys((event.target as any).elements).forEach(
                  (key) => {
                    let element = (event.target as any)[key];
                    if (element.type !== "submit") {
                      body[element.name] =
                        key === "fluxed-mc-e"
                          ? `${element.value}`.toLowerCase()
                          : element.value;
                    }
                  }
                );

                const result = await fetch(
                  `${config.apiUrl}/ikon/v1/newsletter-subscribe`,
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      ...body,
                      secret: config.newsletterSecret,
                    }),
                  }
                )
                  .then((response) => response.json())
                  .then((data) => data);

                if (result?.status === 200) {
                  setFormState({
                    ...formState,
                    submitError: false,
                    submitted: true,
                    submitting: false,
                  });
                } else {
                  setFormState({
                    ...formState,
                    submitError: true,
                    submitted: false,
                    submitting: false,
                  });
                }
              }
            }
          }}
        >
          <StyledFormFields>
            <div className="form-fields">
              <div
                className={`form-field${
                  !!formState?.touched?.email &&
                  !!formState?.errors?.email
                    ? " error"
                    : ""
                }`}
              >
                <input
                  type="hidden"
                  name="name"
                  ref={hiddenFieldRef}
                />
                <div className="text">
                  <VisuallyHidden>
                    <label htmlFor="fuxed_mc_1">
                      Email address <abbr>required</abbr>
                    </label>
                  </VisuallyHidden>
                  <NewsletterInput
                    id="fuxed_mc_1"
                    name="fluxed-mc-e"
                    type="email"
                    placeholder="your@email.com"
                    required={true}
                    aria-label="email address"
                    aria-describedby="fluxed-mc-1-e"
                    aria-invalid={
                      !!formState?.touched?.email &&
                      !!formState?.errors?.email
                    }
                    autoComplete="none"
                    isError={
                      !!formState?.touched?.email &&
                      !!formState?.errors?.email
                    }
                    onChange={(event) => {
                      if (formState.submitting) return;
                      setFormState({
                        ...formState,
                        touched: {
                          ...formState.touched,
                          email: true,
                        },
                        errors: {
                          ...formState.errors,
                          email:
                            !event.target.checkValidity() ||
                            event.target.value.split("@").length !=
                              2 ||
                            (event.target.value.split("@").length >
                              1 &&
                              event.target.value
                                .split("@")[1]
                                .indexOf(".") === -1),
                        },
                      });
                    }}
                  />
                  <NewsletterButton
                    type="submit"
                    disabled={formState.submitting}
                    isLoading={formState.submitting}
                  >
                    {formState.submitting && (
                      <LoadingIcon isLoading size={8} />
                    )}
                    Subscribe
                  </NewsletterButton>
                </div>
                {formState.triedToSubmit &&
                  !!formState?.touched?.email &&
                  !!formState?.errors?.email && (
                    <span
                      className="error-message"
                      id="fluxed-mc-1-e"
                    >
                      Please enter your email address
                    </span>
                  )}
              </div>
              <div
                className={`form-field consent${
                  !!formState?.touched?.consent &&
                  !!formState?.errors?.consent
                    ? " error"
                    : ""
                }`}
              >
                <div className="checkboxes">
                  <input
                    id="fuxed_mc_2_1"
                    type="checkbox"
                    name="fluxed-mc-c"
                    required={true}
                    aria-invalid={
                      !!formState?.touched?.consent &&
                      !!formState?.errors?.consent
                    }
                    value="1"
                    onChange={(event) => {
                      if (formState.submitting) return;

                      setFormState({
                        ...formState,
                        touched: {
                          ...formState.touched,
                          consent: true,
                        },
                        errors: {
                          ...formState.errors,
                          consent: !event.target.checkValidity(),
                        },
                      });
                    }}
                  />

                  <label
                    className="gfield_consent_label"
                    htmlFor="fuxed_mc_2_1"
                  >
                    I CONSENT TO RECEIVE EMAILS FROM IKON
                    <VisuallyHidden>required</VisuallyHidden>
                  </label>

                  <div className="description">
                    PLEASE READ OUR{" "}
                    <Link href="/legal-policy/" passHref>
                      <a style={{textDecoration:"underline"}}>PRIVACY POLICY</a>
                    </Link>{" "}
                    TO LEARN HOW WE HANDLE YOUR DATA
                  </div>
                </div>
              </div>
            </div>
          </StyledFormFields>
        </form>
      )}
    </Form>
  )
}
