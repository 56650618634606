import React from "react";
import styled from "styled-components";
import { PageMargins } from "~/components/styled/PageMargins";
import { useSettingsContext } from "~/providers/SettingsContextProvider";

import { SocialMediaIcons } from "../ui/SocialMediaIcons";
import { Coin } from "../ui/Coin";
import { MenuFooter } from "./Menus/MenuFooter";
import { MenuMain } from "./Menus/MenuMain";
import { Heading } from "../ui/Heading";

import { NewsletterForm } from "./NewsletterForm";

const Grid = styled.div<{
  topBorder?: boolean;
}>`
  border-top: ${({ topBorder }) => (topBorder ? "1px solid #000;" : "none")};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "navigation"
    "address"
    "stayintouch"
    "funding"
    "copyright";

  & a {
    text-decoration: none;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding-top: ${
            props.topBorder
              ? props.theme.spaceFontTop(breakpoint, "h2Section")
              : 0
          };
          column-gap: ${props.theme.gutterPx(breakpoint)};

          min-height: ${
            ["base", "mobile"].includes(breakpoint)
              ? "auto"
              : props.theme.spacePx(breakpoint, 1)
          }
        `;
    })}

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "navigation navigation"
      "address stayintouch"
      "funding funding"
      "copyright copyright";
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "navigation navigation navigation navigation navigation navigation"
      "address address stayintouch stayintouch stayintouch stayintouch"
      "funding funding funding copyright copyright copyright"
      ;
  }
`;

export const Block = styled.div`
  width: 100%;

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
          margin-top:0;
          margin-bottom: ${props.theme.spacePx(breakpoint, 7)};
        `;
      }
    )}
`;

const Navigations = styled.div`
  grid-area: navigation;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    "nav1 nav2"
    "coin coin";

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "nav1 nav2 coin";
    grid-template-rows: auto;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap: ${props.theme.gutterPx(breakpoint)};
        row-gap: ${props.theme.gutterPx(breakpoint)};
          padding-top: ${props.theme.spacePx(breakpoint, 8)};
          min-height: ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 1 : 2
          )};
          padding-bottom: calc(
            ${props.theme.spacePx(
              breakpoint,
              ["base", "mobile"].includes(breakpoint) ? 6 : 5
            )}
            - ${props.theme.spacePx(breakpoint, 8)}
          );
        `;
    })}
`;

const Address = styled.div`
  grid-area: address;

  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
          margin-bottom: ${props.theme.spaceFontBottom(breakpoint, 7, "body")};
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
            margin-bottom: ${props.theme.spacePx(breakpoint, 9)};

            > p:last-child {
              margin-bottom: 0px;
            }
          `;
    })}
`;

const CoinContainer = styled.div`
  grid-area: coin;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Social = styled.div`
  display: flex;
  grid-area: social;
  justify-content: space-between;

  flex-direction: column;

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-bottom: ${props.theme.spaceFontBottom(breakpoint, 7, "body")};
        `;
    })}

  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const Copyright = styled.div`
  grid-area: copyright;

  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, "caption")};
        `;
    })}

  ${({ theme }) => theme.breakpoints.desktop} {
    align-self: end;
    justify-self: end;
  }
`;

const Funding = styled.div`
  grid-area: funding;

  & img {
    max-width: 100%;
    height: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > aside {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        > aside {
          padding-top: ${props.theme.spacePx(breakpoint, 8)};
          grid-column-gap: ${props.theme.spacePx(breakpoint, 8)};
        }
        margin-top: ${props.theme.spacePx(breakpoint, 7)};
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "tabletLandscape", "desktop", "screen"], (breakpoint: string) => {
      return `
        > aside {
          padding-top: 0;
          grid-column-gap: ${props.theme.spacePx(breakpoint, 8)};
        }
        margin-top: ${props.theme.spacePx(breakpoint, 7)};
      `;
    })}

  ${({ theme }) => theme.breakpoints.tablet} {
    > aside {
      width: 80%;
      max-width: 600px;
    }

    > p {
      width: 80%;
      max-width: 600px;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, "caption")};
        `;
    })}
`;

const StayInTouch = styled.div`
  grid-area: stayintouch;
  width: 100%;

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
        column-gap: ${props.theme.spacePx(breakpoint, 7)};  
      `;
      }
    )}
`;

const FormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding-top: ${props.theme.spacePx(breakpoint, 9)};
        padding-bottom: ${props.theme.spacePx(breakpoint, 7)};
        p {
          margin-bottom: ${props.theme.spacePx(breakpoint, 7)};
        }
      `;
    })}
`;

const Links = styled.div``;

export const Footer = () => {
  const settings = useSettingsContext();
  

  return (
    <PageMargins spaceBottom={7}>
      <Grid topBorder={true}>
        <Navigations>
          <MenuMain id="menu-main-in-footer" />
          <MenuFooter id="menu-footer" />
          <CoinContainer>
            <Coin />
          </CoinContainer>
        </Navigations>

        <StayInTouch>
          <Social>
            <Block>
              <div>
                <Heading heading="h4" space={8}>
                  STAY IN TOUCH
                </Heading>

                <Links>
                  <SocialMediaIcons />
                </Links>
              </div>
            </Block>
          </Social>
          <FormContainer>
            <Block>
              <div>
                <Heading heading="h4" space={8}>
                  SUBSCRIBE TO OUR NEWSLETTER
                </Heading>
                <NewsletterForm />
              </div>
            </Block>
          </FormContainer>
        </StayInTouch>
        <Address
          dangerouslySetInnerHTML={{
            __html: settings?.options?.menuContactInformation ?? "",
          }}
        />

        <Funding>
          <p>
            Ikon is supported using public funding by the National Lottery
            through Arts Council England, and Birmingham City Council.
          </p>
          <aside>
            <a
              href="https://www.artscouncil.org.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/img/ace.png" alt="Logo Arts Council England" />
            </a>
            <a
              href="https://www.birmingham.gov.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/img/bcc.png" alt="Logo Birmingham City Council" />
            </a>
          </aside>
        </Funding>
        <Copyright
          dangerouslySetInnerHTML={{
            __html: settings?.options?.copyrightNotice ?? "",
          }}
        />
      </Grid>
    </PageMargins>
  );
};
