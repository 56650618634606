import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";

import { useRouter } from "next/router";
import { FlexibleHeading } from "../styled/FlexibleHeading";
import { useSettingsContext } from "~/providers/SettingsContextProvider";

const COOKIE_NAME = "ikon-cookie-np-shown";
let pageview = 1;

import styled from "styled-components";
import Button from "../styled/Button";
import { NewsletterForm } from "./NewsletterForm";

const Screen = styled("div")<{ opacity: number }>`
  position: fixed;
  z-index: 3999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.5s;
  background-color: #fff;
  cursor: pointer;
`;


const FormContainer = styled("div")`
  border: 1px solid #000;
  background-color: #fff;
  width: 100%;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding: ${props.theme.spacePx(
          breakpoint,
          8
        )};

        & .text {
          width: 100%;
        }

        & .text input {
          min-width: 25vw;
        }
      `;
    })}
`;

const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-end;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s;
  @media (any-pointer: fine) {
    &:hover {
      background-color: #555;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        padding: 0.05em ${props.theme.spacePx(
          breakpoint,
          9
        )} 0  ${props.theme.spacePx(breakpoint, 9)};
      `;
    })}
`;

const Aside = styled.aside<{ opacity: number }>`
  position: fixed;
  background: #fff;
  transition: opacity 0.5s;
  z-index: 4000;
  opacity: ${({ opacity }) => opacity};
  background-color: var(--ikon-hl-color, #ff0);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        max-width: calc(100vw - ${(
          props.theme.space(breakpoint, 8) * 2
        ).toFixed(2)}px);
        max-height: calc(100svh - ${(
          props.theme.space(breakpoint, 8) * 2
        ).toFixed(2)}px);
        min-height: ${props.theme.spacePx(breakpoint, 5)};            
        left: ${props.theme.spacePx(breakpoint, 8)};            
        bottom: ${props.theme.spacePx(breakpoint, 8)};            
        padding: ${props.theme.spacePx(breakpoint, 8)};
        row-gap: ${props.theme.spacePx(breakpoint, 8)};
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
        left: 50%;
        top: 50%;
        bottom: auto;
        transform: translateX(-50%) translateY(-50%);
        
        width: ${(
          props.theme.space(breakpoint, 2) * 2
        ).toFixed(2)}px;`
    })}
`;

const CTA = styled.div`
  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
          ${props.theme.textStyle(breakpoint, `body`)};
          p {
            ${props.theme.textStyle(breakpoint, `body`)};
          }
          
          margin-bottom: ${props.theme.spaceFontBottom(breakpoint, 7, "body")};
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
            ${props.theme.textStyle(breakpoint, `body`)};
            p {
              ${props.theme.textStyle(breakpoint, `body`)};
            }
            margin-bottom: ${props.theme.spacePx(breakpoint, 9)};

            > p:last-child {
              margin-bottom: 0px;
            }
          `;
    })}
`;

export const NewsletterPopup = () => {
  const settings = useSettingsContext();
  const [showPopup, setShowPopup] = useState(false);
  const [fadingOut, setFadeingOut] = useState(false);
  const router = useRouter();

  function fadeOut() {
    setFadeingOut(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 750);
  };

  function close() {
    try {
      Cookies.set(COOKIE_NAME, "yes", { expires: 3 });
    } catch (e) {}

    fadeOut();
  }
  const trackView = useCallback(
    () => {
      if (pageview == 1) {
        try {
          const showNewsletterPopup = Cookies.get(COOKIE_NAME);

          if (showNewsletterPopup !== "yes" && showNewsletterPopup !== "no") {
            setShowPopup(true);
          }
        } catch (e) {
          setShowPopup(true);
        }
      }
      pageview++;
    },
    []
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const tEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };

    if (typeof document !== "undefined") {
      document.body.addEventListener("keyup", tEscape);
    }

    router.events.on("routeChangeComplete", trackView);
    return () => {
      if (typeof document !== "undefined") {
        document.body.removeEventListener("keyup", tEscape);
        router.events.off("routeChangeComplete", trackView);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showCTA = typeof settings?.options?.newsletterPopup === "string" && settings?.options?.newsletterPopup.trim();
  return (
    <>
      {showPopup && (
        <>
          <Screen onClick={close} opacity={fadingOut ? 0 : 0.9} aria-hidden="true"/>
          <Aside
            role="alertdialog"
            aria-modal="true"
            aria-labelledby="cpopup_label"
            aria-describedby="cpopup_desc"
            opacity={fadingOut ? 0 : 1}
          >
            <FlexibleHeading heading="h3">SUBSCRIBE TO OUR NEWSLETTER</FlexibleHeading>
            {showCTA && <CTA
              dangerouslySetInnerHTML={{
                __html: settings.options.newsletterPopup,
              }}
            />}
            <FormContainer>
              <NewsletterForm />
            </FormContainer>
            <CloseButton onClick={close}>Close</CloseButton>
          </Aside>
        </>
      )}
    </>
  );
};
